import { configureStore, combineSlices } from "@reduxjs/toolkit"

import { signUpSlice } from "./slices/signUpSlice"
import { couponSlice } from "./slices/couponSlice"
import { stepperSlice } from "./slices/stepperSlice"
import { toastSlice } from "./slices/toastAlertSlice"

const rootReducer = combineSlices(
  signUpSlice,
  couponSlice,
  stepperSlice,
  toastSlice
)

export const store = configureStore({
  reducer: rootReducer,
})
