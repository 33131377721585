import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { CloseButton } from "react-bootstrap"
import Toast from "react-bootstrap/Toast"
import ToastContainer from "react-bootstrap/ToastContainer"

import { closeToast, selectToast } from "../redux/slices/toastAlertSlice"

const ToastAlert = () => {
  const dispatch = useDispatch()
  const { show, variant, message } = useSelector(selectToast)

  const close = () => {
    dispatch(closeToast())
  }

  return (
    <ToastContainer position="top-end" className="position-fixed p-4">
      <Toast
        show={show && message}
        delay={4000}
        autohide
        animation
        bg={variant}
        onClose={close}
        className="position-relative"
      >
        <CloseButton
          onClick={close}
          className="position-absolute top-0 end-0 p-2"
        />
        <Toast.Body className="text-white p-3">{message}</Toast.Body>
      </Toast>
    </ToastContainer>
  )
}

export default ToastAlert
