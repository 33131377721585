import { createAppSlice } from "../createAppSlice"

const initialState = {
  id: "",
  name: "",
  amount_off: 0,
  appliesTo: "",
}

export const couponSlice = createAppSlice({
  name: "coupon",
  initialState,
  reducers: {
    setCoupon: (state, action) => {
      state = action.payload
      return state
    },
    resetState: () => {
      return initialState
    },
  },
  selectors: {
    selectCoupon: coupon => coupon,
  },
})

export const { setCoupon, resetState } = couponSlice.actions

export const { selectCoupon } = couponSlice.selectors
