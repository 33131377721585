import { createAppSlice } from "../createAppSlice"

const initialState = {
  show: false,
  varian: "success",
  message: "",
}

export const toastSlice = createAppSlice({
  name: "toast",
  initialState,
  reducers: {
    closeToast: state => {
      state.show = false
    },
    openToast: (state, action) => {
      state = action.payload
      state.show = true
      return state
    },
  },
  selectors: {
    selectToast: state => state,
  },
})

export const { closeToast, openToast } = toastSlice.actions

export const { selectToast } = toastSlice.selectors
