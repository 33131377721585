import React from "react"
import { Provider } from "react-redux"

import { store } from "./src/redux/store"
import ToastAlert from "./src/components/ToastAlert"

const WrapWithProvider = ({ element }) => {
  return (
    <Provider store={store}>
      {element}
      <ToastAlert />
    </Provider>
  )
}

export default WrapWithProvider
