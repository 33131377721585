import WrapWithProvider from "./wrap-with-provider"

import "./src/styles/global.scss"

const tawkId = process.env.GATSBY_TAWK_ID
const tawkKey = process.env.GATSBY_TAWK_KEY

export function onInitialClientRender() {
  // eslint-disable-next-line
  var Tawk_API = Tawk_API || {},
    // eslint-disable-next-line
    Tawk_LoadStart = new Date()
  ;(function () {
    var s1 = document.createElement("script"),
      s0 = document.getElementsByTagName("script")[0]
    s1.async = true
    s1.src = `https://embed.tawk.to/${tawkKey}/${tawkId}`
    s1.charset = "UTF-8"
    s1.setAttribute("crossorigin", "*")
    s0.parentNode.insertBefore(s1, s0)
  })()
}

export const wrapRootElement = WrapWithProvider
