import { createAppSlice } from "../createAppSlice"

const initialState = {
  steps: [],
  currentStep: "",
}

export const stepperSlice = createAppSlice({
  name: "stepper",
  initialState,
  reducers: {
    setSteps: (state, action) => {
      state.steps = action.payload
    },
    setCurrentStep: (state, action) => {
        state.currentStep = action.payload
    }
  },
  selectors: {
    selectSteps: state => state.steps,
    selectCurrentStep: state => state.currentStep
  },
})

export const { setSteps, setCurrentStep } = stepperSlice.actions

export const { selectSteps, selectCurrentStep } = stepperSlice.selectors
