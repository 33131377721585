import { createAppSlice } from "../createAppSlice"

const initialState = {
  signUpType: "",
  personalInfo: {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  },
  billingInfo: {
    country: "",
    state: "",
    address: "",
    city: "",
    zipCode: "",
  },
  serviceType: "",
  plan: null,
  freeMonths: null
}

export const signUpSlice = createAppSlice({
  name: "signUp",
  initialState,
  reducers: {
    setSignUpType: (state, action) => {
      state.signUpType = action.payload
    },
    setPersonalInfo: (state, action) => {
      state.personalInfo = action.payload
    },
    setBillingInfo: (state, action) => {
      state.billingInfo = action.payload
    },
    setService: (state, action) => {
      state.serviceType = action.payload
    },
    setPlan: (state, action) => {
      state.plan = action.payload
    },
    setFreeMonths: (state, action) => {
      state.freeMonths = action.payload
    },
    resetState: () => {
      return initialState
    },
  },
  selectors: {
    selectPersonalInfo: signUp => signUp.personalInfo,
    selectBillingInfo: signUp => signUp.billingInfo,
    selectPlan: signUp => signUp.plan,
    selectService: signUp => signUp.serviceType,
    selectSignUpType: signUp => signUp.signUpType,
    selectFreeMonths: signUp => signUp.freeMonths,
    selectSignUpData: signUp => signUp,
  },
})

export const {
  setPersonalInfo,
  setBillingInfo,
  setService,
  setPlan,
  setSignUpType,
  setFreeMonths,
  resetState,
} = signUpSlice.actions

export const {
  selectPersonalInfo,
  selectBillingInfo,
  selectService,
  selectPlan,
  selectSignUpType,
  selectFreeMonths,
  selectSignUpData,
} = signUpSlice.selectors
